.border-right {
    border-right-style: solid 1px #ff009d; 
}
.border-bottom {
    border-bottom-style: solid 1px #ff009d;
    
}
ul.highlight {
    color: #ff009d;
    font-size: 1.1rem;
    font-style: italic;
}
.container {
    align-items: center;
}
.contrast {
    color: #fff;
    background: transparent;
    
}
.contrast-banner{
    background: linear-gradient(30deg, #ff009d, #bb15bb) no-repeat center center fixed;
    width: 100%;
    padding:5px;
}
.contrast-header{
    font-size: 1.1rem;
    font-weight: bolder;
    vertical-align: top;
    text-align: left;
}
.left-align {
    text-align: left;
}
.descriptive-title {
    color: #2f366d;
    font-weight: bolder;
    font-size: 1.2rem;
}
.descriptive {
    color: #2f366d;
    font-weight: normal;
    text-align: left;
}
@media (min-width: 600px){
    .float-center{
        align-content: unset;
        margin-left: auto;
        margin-right: auto;
    }
}