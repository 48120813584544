.App {
  text-align: center;
  color: rgb(255, 0, 157);
  }

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.bio-intro{
  font-size:1.1em;
  color: #202c3a;
  font-weight: 550;
  text-align: left;
}
.bio-pic.img-fluid {
  max-width: 250px;
}
.bio-title
{
  background-color: #ff009d;
  color: #fff;
  text-shadow: 0px 0px 2px #b90072;
  max-width: 250px;
  text-align: center;
  
}
body {
  background: inherit;
  font-family: Arial, Helvetica, sans-serif;
  height:100vh;
}
.body-main{
  background-color: #f6faff;
}
.btn a{
  color: #ff009d;
  
}
.btn .wft a{
  color: #fff;
  text-shadow: 0px 0px 1px rgba(0,0,0, 0.9);
  
}
button .wft{
  color: #fff;
  text-align: center;
  text-shadow: 0px 0px 1px rgba(0,0,0, 0.9);
}
button {
  color: #ff009d;
  border:1px solid #ff009d !important;
  text-align: center;
}
.btn a:hover{
  color: #f08;
}
.card-img-top {
  max-width: 75%;
  aspect-ratio: 1;
  align-content: center;
}
.card-title {
  font-weight: 800;
  text-align: center;

}
.contact-info{
  text-align: left;
  max-width: 500px;
}
.resume-Link a, .resume-Link a:hover{
color: #ff009d;
}
.row {
  margin-bottom: 2rem;
  background-color:#f6faff;
}
.homeName h1, .homeName h2{
  font-size: 1.5em;
  padding: 1vw;
  text-align: center;
  color: #5a4770;
  /* text-shadow:1px 1px  rgb(152, 140, 165), 1px -2px 2px rgba(244, 255, 225, 0.9), -1px -2px 3px rgba(255, 255, 255, 0.9); */
  line-height:0.8em;
  font-family: Arial, Helvetica, sans-serif;
}
.homeTitle{
  font-size: 2em;
  font-family: 'Permanent Marker', Arial, Helvetica, sans-serif;
  font-weight: bolder;
}
.list-group-item a{
  color: #FF009D;
}
h1, h2, h3, h4, a {
  color: rgb(255, 0, 157);
}
html {
  background: linear-gradient(180deg, #f6faff, #f6faff) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.item-logo{
  width: 56%;
  overflow: visible;
  stroke: #fff;
  stroke-width: 2;
  stroke-linejoin: round;
  stroke-linecap: round;
}
.paint-size{
  width: 100%;
  overflow: hidden;
  stroke: #fff;
  stroke-width: 2;
  stroke-linejoin: round;
  stroke-linecap: round;
}
.jumbotron{
  padding:none !important;
  margin-bottom: 0px !important;
  background-color: transparent !important;
}
@media (min-width: 880px){
  .jumbotron-fluid {
    height: 450px;
    background-repeat: no-repeat;
    background-image: url('./images/baltimore-developer.png')!important;
    background-color: inherit !important;
    -webkit-background-size: 100%;
    -moz-background-size: 100%;
    -o-background-size: 100%;
    background-size: 100%;
  }
}
@media (min-width: 576px){
  .jumbotron {
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
  }

  .parallax-sizes{
    /* max-height: '350px';
    min-height: '250px'; */
  }
  .card-text {
    padding-left: 20%;
    padding-right: 20%;
  }
}
@media (max-width: 879px){
  .jumbotron-fluid {
    height: 200px;
    background-repeat: no-repeat;
    background-image: url('./images/baltimore-developer.png')!important;
    background-color: inherit !important;
    -webkit-background-size: 100%;
    -moz-background-size: 100%;
    -o-background-size: 100%;
    background-size: 100%;
  }
}
@media (max-width: 575px){
  .parallax-sizes{
    max-height: '350px';
  }
  .parallax-banner.parallax-home{
    vertical-align: top;
    background-position: center center top;
    height: 100% !important;
  }
  .parallax-banner {
    background-size: cover;
    border:none !important;
  }
  .navbar-collapse{
    border: 1px solid rgb(255, 0, 157) !important;
      border-radius: 25px;
  }
}

.logo-container {
  width: 100px;
  height: 100px;
  display: flex;
  place-content: center;
  overflow: hidden;
  background: rgba(255, 0, 157, 0.9);
  border-radius: 50%;
}
.paint-container {
  width: 100%;
  max-height: 300px;
  display: flex;
  place-content: center;
  overflow: hidden;
}
.parallax-banner-layer-0, .parallax-banner-layer-1{
  background-size: 100% !important;
  background-repeat: no-repeat !important;
  border: none !important;
  margin-bottom: 0px !important;
}

.navbar-dark {
  border-color: rgb(255, 0, 157) !important;

}
.navbar-toggler {
  background: rgb(255, 0, 157) !important;
}
.navbar-dark .navbar-toggler-icon {
  stroke: rgb(255, 0, 157) !important;
  color: rgb(255, 0, 157) !important;
}
.navigation {
  font-size: 1.7em;
}
.spin-container {
  width: 150px;
  height: 150px;
  background: rgba(255,255,255,0.25);
  border-radius: 30px;
  border: solid white 1px;
}
  .neomorph-card {
    border: none !important;
    color: rgb(238, 0, 159);
    padding:5px;
    background-color:#f6faff !important;
    margin-bottom: 5px;
    box-shadow: 9px 9px 16px rgba(183, 198, 221, 0.2), inset 9px 9px 8px rgba(183, 198, 221, 0.2), -9px -9px 12px rgba(255,255,255, 0.85), inset -9px -9px 8px rgba(255,255,255, 0.85);
  }
p {
  margin-bottom: 0px;
  padding:1em;
}
.bg-clear {
  background-color: transparent !important;
}
.footer .nav-link  {
  font-size: 0.7em;
  bottom: 5px;
  position: sticky;
}
.nav-link{
  color: rgb(255, 0, 157) !important;
}
.project {
  color: #fff;
}
.project-title {
  color: #fff;
}
.project-description{
  color: #fff;
}
.card {
  border: none !important;
}
.card-img-overlay {
  color: #ff009d !important;
  padding: 2vw;
  vertical-align: middle !important;
  /* background: radial-gradient( rgba(47, 41, 73, 0.9),rgba(51, 44, 83, 0.3),rgba(255,255,255,0.0)); */
}
.card-img-overlay .white-faux-taxidermy {
  color: #fff !important;
  padding: 2vw;
  vertical-align: middle !important;
  /* background: radial-gradient( rgba(47, 41, 73, 0.9),rgba(51, 44, 83, 0.3),rgba(255,255,255,0.0)); */
}
.card-text {
  font-size: 1.3rem;
  margin-block-end: 0em;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.9);
}
.card-text .white-faux-taxidermy{
  font-size: 1.3rem;
  margin-block-end: 0em;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.9);
}
.card-text-no-shadow {
  font-size: 1.3rem;
  margin-block-end: 0em;
  text-shadow: none !important;
}
.project-frame{
  border-radius: 1rem;
  border: 2px solid #ddff76;
  height: fit-content;
}
.skill-set.row, .skill-set.row a{
  color: #696b83 !important;
}
.container-row-project {
  
}
.container-row-project .row {
  border-radius: 2vh;
  background-color: transparent !important;
  color: #ff009d;
  font-size: 1.2rem;
  box-shadow: 9px 9px 16px rgba(183, 198, 221, 0.2), inset 9px 9px 8px rgba(183, 198, 221, 0.2), -9px -9px 12px rgba(255,255,255, 0.85), inset -9px -9px 8px rgba(255,255,255, 0.85);
  /* box-shadow: 6px 6px 12px rgba(68, 49, 90, 0.45), -5px -5px 9px rgba(138, 104, 153, 0.45); */
  text-align: left;
}
.vertical-timeline-element-content.bounce-in {
  box-shadow: 9px 9px 16px rgba(183, 198, 221, 0.2), inset 9px 9px 8px rgba(183, 198, 221, 0.2), -9px -9px 12px rgba(255,255,255, 0.85), inset -9px -9px 8px rgba(255,255,255, 0.85);
}
/* .vertical-timeline-element-content-arrow {
  box-shadow: inset 1px 1px 1px rgba(68, 49, 90, 0.45), 1px 1px 2px rgba(138, 104, 153, 0.45) !important;
} */
.vertical-timeline-element-date {
  font-size: 2em !important;
}
.vertical-timeline-element-content p{
  color:#ff009d;
}
.vertical-timeline-element-date{
  color:#ff009d;
}